import { managerAuthService } from '../../service/authApi'
import { updateError, updateStart, updateSuccess } from '../reducer/useSlice'

export const signInAuth = async (user, dispatch) => {
  try {
    const res = await managerAuthService.signIn(user)
    console.log('🚀 ~ file: apiUser.jsx:7 ~ signInAuth ~ res:', res)
    dispatch(updateStart(res.data))
    dispatch(updateSuccess(res.data))
  } catch (err) {
    dispatch(updateError())
  }
}
