import React, { useEffect } from 'react'
import { useGoogleLogin } from 'react-google-login'
import { Button } from '../Button'
import { refreshTokenSetup } from '../RefreshTokenComponent'

import { signInAuth } from '../../redux/action/apiUser'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gapi } from 'gapi-script'

const clientId =
  '509561184649-blvif5ku7e88h4k5jh09t0qst4u9iqnd.apps.googleusercontent.com'
export const LoginGoogleButton = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSuccess = response => {
    console.log('[Login Success] currentUser: ', response.profileObj)
    const user = {
      username: response.profileObj.email,
      provider: 'google',
      accessToken: response.accessToken,
    }
    refreshTokenSetup(response)
    signInAuth(user, dispatch)
    if (response) {
      navigate('/')
    }
  }

  const onFailure = res => {
    console.log('Login failed] res: ', res)
  }
  useEffect(() => {
    const clientId = 'enter your client id here'
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      })
    }
    gapi.load('client:auth2', start)
  }, [])
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
  })
  return (
    <Button icon onClick={signIn} className="btn-google">
      <img src="/google_8.png" width="35" height="35" alt="Logo Google" />
      <div className="buttonText">Sign in with Google</div>
    </Button>
  )
}
