import React from 'react'
import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './header'
import Footer from './footer'
import Home from '../pages/Home'
import AboutPage from '../pages/About'
import ContactPage from '../pages/Contact'

export const UserTempalete = () => {
  return (
    <Fragment>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </Fragment>
  )
}
