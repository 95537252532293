import { useGoogleLogout } from 'react-google-login'
import { Button } from '../Button'
import * as Icon from 'react-feather'
import { useNavigate } from 'react-router-dom'

const clientId =
  '509561184649-blvif5ku7e88h4k5jh09t0qst4u9iqnd.apps.googleusercontent.com'
export const LogoutGoogleButton = () => {
  const navigate = useNavigate()
  const onLogoutSuccess = () => {
    localStorage.clear()
    window.localStorage.clear()
    window.sessionStorage.clear()
    navigate('/sign-in')
  }
  const onFailure = res => {
    console.log('Handle failure cases', res)
  }
  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  })
  return (
    <Button onClick={signOut} className="flex gap-x-5 items-center p-2 ">
      <Icon.LogOut size={32} color="black" />
      <h1 className="text-lg font-semibold sm:text-base">Log out</h1>
    </Button>
  )
}
