/* eslint-disable no-useless-constructor */
import { baseService } from './baseService'

export class ManagerAuthService extends baseService {
  constructor() {
    super()
  }
  signIn = user => {
    return this.post(`api/auth/sign-in`, user)
  }
  signUp = formData => {
    return this.post(`api/auth/sign-up`, formData)
  }
}

export const managerAuthService = new ManagerAuthService()
