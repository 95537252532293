import { createSlice } from '@reduxjs/toolkit'
import { USER_LOGIN } from '../../util/setting/config'

let user = {}
if (localStorage.getItem(USER_LOGIN)) {
  user = JSON.parse(localStorage.getItem(USER_LOGIN))
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userLogin: user,
  },
  reducers: {
    updateStart: (state, action) => {
      state.pending = true
      void (state.userLogin = action.payload.payload)
    },
    updateError: state => {
      state.pending = false
      state.error = true
    },
    updateSuccess: (state, action) => {
      state.pending = false
      state.error = false
      localStorage.setItem('USER_LOGIN', JSON.stringify(action.payload.payload))
      localStorage.setItem('TOKEN', action.payload.accessToken)
      void (state.userLogin = action.payload.payload)
      return { ...state }
    },
  },
})
export const { updateError, updateStart, updateSuccess } = userSlice.actions
export default userSlice.reducer
