const Search = () => {
  return (
    <>
      <input
        type="search"
        className="input-search"
        placeholder="Search by collection, movie"
        aria-label="Search"
        aria-describedby="button-addon2"
      />
    </>
  )
}

export default Search
