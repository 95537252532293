/* eslint-disable no-undef */
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import LoginPage from './pages/Login'
import { createBrowserHistory } from 'history'
import { UserTempalete } from './template'

export const history = createBrowserHistory()
function App() {
  return (
    <Router history={history}>
      <Routes>
        <Route path="/sign-in" exact element={<LoginPage />} />
        <Route path="/*" element={<UserTempalete />} />
      </Routes>
    </Router>
  )
}

export default App
