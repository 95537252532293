/* eslint-disable eqeqeq */
import React, { memo, useCallback, useEffect } from 'react'
import { useState } from 'react'
import * as Icon from 'react-feather'
import Search from '../../components/Search'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { useSelector } from 'react-redux'
import { LogoutGoogleButton } from '../../components/LogoutGoogle'
import { isEmptyOrUndefined } from '../../util/setting/isEmpty'

const Header = () => {
  const navigate = useNavigate()
  const [openHamBurgerMenu, setOpenHamBurgerMenu] = useState(false)
  const [openProfileMenu, setOpenProfileMenu] = useState(false)
  const [openSearch, setOpenSearch] = useState(true)
  const updateSuccess = useSelector(state => {
    if (isEmptyOrUndefined(state.user.userLogin)) {
      navigate('/sign-in')
    }
    return state.user.userLogin
  })

  const logOut = useCallback(() => {
    setOpenProfileMenu(false)
    setOpenHamBurgerMenu(false)
    setOpenSearch(false)
    localStorage.clear()
    localStorage.clear()
    navigate('/sign-in')
  }, [navigate, setOpenProfileMenu])

  useEffect(() => {
    setOpenProfileMenu(false)
    setOpenHamBurgerMenu(false)
    setOpenSearch(false)
    const screenWidth = window.innerWidth
    function handleResize() {
      if (screenWidth >= 1024) {
        setOpenHamBurgerMenu(false)
        setOpenProfileMenu(false)
      }
    }
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <nav className="navbar-header z-[102]">
      <div className="flex flex-row items-center justify-between px-5 w-[100%] mx-auto z-[101]">
        <img
          src="/favicpn.ico"
          className={`lg:w-28  ${openSearch ? 'hidden lg:block' : ''} `}
          alt="Logo Image"
        />
        <div
          className={`lg:block sm:w-full ${
            openSearch ? 'block z-[120]' : 'hidden'
          }`}
        >
          <div className="flex items-center gap-x-2 relative lg:w-[480px] sm:w-full">
            <div className="absolute z-[50] top-[50%] translate-y-[-50%] left-5">
              <Icon.Search size={16} color="gray" />
            </div>
            <Search />
            <button
              className="btn-search lg:hidden"
              onClick={() => setOpenSearch(false)}
            >
              <Icon.X size={32} color="black" />
            </button>
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <div className="flex lg:hidden">
            {openHamBurgerMenu ? (
              <button onClick={() => setOpenSearch(false)}>
                <Icon.Search size={56} color="black" />
              </button>
            ) : (
              <button
                className={`${openSearch ? 'hidden' : ''}`}
                onClick={() => setOpenSearch(true)}
              >
                <Icon.Search size={56} color="black" />
              </button>
            )}
          </div>
          <>
            <div
              className={`flex items-center ${
                openSearch ? 'hidden lg:block' : ''
              }`}
            >
              <button
                className="btn-infomation_user"
                onClick={() => setOpenProfileMenu(!openProfileMenu)}
              >
                <img
                  src="/default-avatar.png"
                  className="w-14 h-14 rounded-[50%]"
                  alt="Avarta Here"
                />
                <p>{updateSuccess.username}</p>
              </button>
              <ul className={`profile-menu ${openProfileMenu ? '' : 'hidden'}`}>
                <li>
                  <NavLink to={`/profile/`}>
                    <Button className="flex gap-x-5 items-center p-2">
                      <Icon.User size={32} color="black" />
                      <h1 className="text-lg font-semibold sm:text-base">
                        Profile
                      </h1>
                    </Button>
                  </NavLink>
                </li>
                <li>
                  <div className="flex items-center gap-x-6 system">
                    {updateSuccess.provider === 'system' ? (
                      <Button
                        onClick={logOut}
                        className="flex gap-x-5 items-center p-2 "
                      >
                        <Icon.LogOut size={32} color="black" />
                        <h1 className="text-lg font-semibold sm:text-base">
                          Log out
                        </h1>
                      </Button>
                    ) : (
                      <LogoutGoogleButton />
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </>
        </div>
      </div>
    </nav>
  )
}

export default memo(Header)
